import "styles/pages/recipie.scss"

import React, { useState, useEffect } from "react"
import { FacebookShareButton, PinterestShareButton } from "react-share"
import { Link } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const Recipie = ({ pageContext }) => {
  const { data, products } = pageContext
  const title = data.title

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const [filteredProducts, setFilteredProducts] = useState([])

  useEffect(() => {
    const relatedProducts =
      data.acfRecipies.recipeRelatedProducts &&
      data.acfRecipies.recipeRelatedProducts.map(item => item.id)

    setFilteredProducts(
      products.nodes.filter(
        item => relatedProducts && relatedProducts.includes(item.id)
      )
    )
  }, [data.acfRecipies.recipeRelatedProducts, products.nodes])

  return (
    <Layout>
      <Seo
        title={data.seo.title ? data.seo.title : title}
        description={data.seo.metaDesc && data.seo.metaDesc}
        ogImage={
          data?.seo?.opengraphImage?.sourceUrl
            ? data?.seo.opengraphImage?.sourceUrl
            : data?.featuredImage?.node?.sourceUrl
        }
      />
      <PageHeader title={title} />
      <Breadcrumbs title={title} parent="Uppskriftir" />

      <section className="recipie">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="recipie-sidebar">
                <h4>Bólkar</h4>
                <h3>
                  <span
                    className={`cat--${data.acfRecipies.recipeCategory}`}
                  ></span>{" "}
                  {data.acfRecipies.recipeCategory &&
                  data.acfRecipies.recipeCategory === "breakfast"
                    ? "Morgummatur"
                    : data.acfRecipies.recipeCategory === "baking"
                    ? "Baking"
                    : data.acfRecipies.recipeCategory === "desert"
                    ? "Omaná"
                    : data.acfRecipies.recipeCategory === "dinner"
                    ? "Døgverði"
                    : data.acfRecipies.recipeCategory === "dressing"
                    ? "Dressingur"
                    : data.acfRecipies.recipeCategory}
                </h3>
              </div>
            </div>
            <div className="col-md-9">
              <div className="recipie-image">
                {data.featuredImage && (
                  <img
                    className="img-fluid"
                    src={data?.featuredImage?.node?.sourceUrl}
                    alt=""
                  />
                )}
              </div>

              <div className="row">
                <div className="col-sm-8">
                  {filteredProducts.length > 0 && (
                    <div className="recipie-related">
                      <p>TÚ KANST BRÚKA:</p>
                      <div className="recipie-related__row">
                        {filteredProducts.map((item, index) => (
                          <Link
                            className="recipie-related__item"
                            to={`/${item.slug}/`}
                            key={index}
                          >
                            <img
                              className="img-fluid"
                              src={
                                item.acfProduct.productRecipeThumbnail.sourceUrl
                              }
                              alt={item.title}
                            />
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-sm-4">
                  <div className="recipie-share">
                    Share:{" "}
                    <FacebookShareButton url={location}>
                      <img
                        src={
                          require("assets/images/ico-share-facebook.png")
                            .default
                        }
                        alt="ico-share-facebook"
                      />
                    </FacebookShareButton>
                    <PinterestShareButton url={location}>
                      <img
                        src={
                          require("assets/images/ico-share-pinterest.png")
                            .default
                        }
                        alt="ico-share-facebook"
                      />
                    </PinterestShareButton>
                  </div>
                </div>
              </div>

              <div className="recipie-info">
                <p>{data.date.split("T")[0].split("-").reverse().join(".")}</p>
                <h2>{title}</h2>
              </div>

              <div className="recipie-content">
                {data.acfRecipies.recipeShortDescription && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.acfRecipies.recipeShortDescription,
                    }}
                  />
                )}

                <h4>ArbeiÐsháttur:</h4>
                {data.acfRecipies.recipeDescription && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.acfRecipies.recipeDescription,
                    }}
                  />
                )}

                <h4>Tilfar</h4>
                {data.acfRecipies.recipeIngredients && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.acfRecipies.recipeIngredients,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Recipie
